<template>
    <v-container>
        <div class="text-h3" style="margin:20px 0">Om</div>
        <p>BrukMat er en næringsoversikt og matplanlegger som prioriterer ingredienser først. BrukMat er for de med allergi, spesielle kostholdskrav og for de som ønsker å redusere matsvinn. Tjenesten er gratis for privatpersoner.</p>
        <p>Vi bruker neste-generasjons teknologi for å gjøre det som ikke ville vært mulig med tradisjonelle måter.</p>
        <p>Takk for din interesse!</p>
        <div class="text-h4" style="margin:20px 0">Hvordan bruke tjenesten</div>
        <p>På vår hovedside så kan du legge inn ingredienser du ønsker å søke etter. Når du har lagt til en ingrediens vil dette havne i "matkurven" din. For å få fram matkurven så kan du trykke på eplet opp i øvre høyre hjørne. Med matkurven så kan du få oversikt over makronæring og ha mulighet for å se flere detaljer på hver enkelt ingrediens. For å finne oppskrifter så trykker du på knappen "finn oppskrifter" som du finner på bunnen av matkurven.</p>
        <div class="text-h4" style="margin:20px 0">Kommersiell Virksomhet</div>
        <p>Det er tenkt at ernæringsfysiologer, resturanter og annen kommersiell virksomhet skal kunne betale for tjenesten og bruke den for å enkelt kunne finne oppskrifter med spesielle krav, regne ut næringsinnhold på oppskrifter m.m. Hvis du driver en kommersiell virksomhet og ønsker å bruke tjenesten før vi har fått på plass betalingsløsningene våre så kan du ta kontakt med oss per epost med bruk av kontaktskjemaet vårt på <a href="https://bitkraken.no/"> bitkraken.no</a></p>

            <div class="text-h4" style="margin:20px 0">Kilder</div>
            <p>Vi innhenter opplysninger om næring fra Matvaretabellen 2020. Mattilsynet. www.matvaretabellen.no.</p>
    </v-container>
</template>
<script>
    export default {
        metaInfo: {
            // override the parent template and just use the above title only
            title: 'Om',
            // all titles will be injected into this template
            titleTemplate: '%s | Brukmat',
            meta: [
              { name: 'description', content: 'BrukMat er en næringsoversikt og matplanlegger som prioriterer ingredienser først. BrukMat er for de med allergi, spesielle kostholdskrav og for de som ønsker å redusere matsvinn.' }
            ]
        }
    }
</script>